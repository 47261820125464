module.exports = {
  times: [
    { value: 1, label: '9:00AM' },
    { value: 2, label: '9:30AM' },
    { value: 3, label: '10:00AM' },
    { value: 4, label: '10:30AM' },
    { value: 5, label: '11:00AM' },
    { value: 6, label: '11:30AM' },
    { value: 7, label: '12:00PM' },
    { value: 8, label: '12:30PM' },
    { value: 9, label: '1:00PM' },
    { value: 10, label: '1:30PM' },
    { value: 11, label: '2:00PM' },
    { value: 12, label: '2:30PM' },
    { value: 13, label: '3:00PM' },
    { value: 14, label: '3:30PM' },
    { value: 15, label: '4:00PM' },
    { value: 16, label: '4:30PM' },
    { value: 17, label: '5:00PM' },
    { value: 18, label: '5:30PM' }
  ],
  timesForDash: {
    1: '9:00AM',
    2: '9:30AM',
    3: '10:00AM',
    4: '10:30AM',
    5: '11:00AM',
    6: '11:30AM',
    7: '12:00PM',
    8: '12:30PM',
    9: '1:00PM',
    10: '1:30PM',
    11: '2:00PM',
    12: '2:30PM',
    13: '3:00PM',
    14: '3:30PM',
    15: '4:00PM',
    16: '4:30PM',
    17: '5:00PM',
    18: '5:30PM'
  }
};
